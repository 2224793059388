import {createRouter, createWebHashHistory} from 'vue-router'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: "/", component: () => import("@/components/Index")}
    ]
})

export default router;


